import styled from 'styled-components';

const Wrapper = styled.svg`
  width: 24px;
  height: 24px;

  @media (max-width: 400px) {
    width: 20px;
    height: 20px;
  }
`;
export const KakaoIcon = () => {
  return (
    <Wrapper viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5 19.8986C19.4036 19.8986 25 15.5551 25 10.1971C25 4.83913 19.4036 0.495628 12.5 0.495628C5.59644 0.495628 0 4.83913 0 10.1971C0 13.4517 2.06487 16.3319 5.2333 18.0918C5.436 18.2044 5.54246 18.4376 5.48622 18.6626L4.39722 23.0186C4.28918 23.4507 4.75757 23.7978 5.13954 23.5686L11.1909 19.9378C11.2792 19.8848 11.3816 19.8606 11.4843 19.867C11.8193 19.888 12.158 19.8986 12.5 19.8986Z"
        fill="#131201"
      />
    </Wrapper>
  );
};

import axios from 'axios';

// const URL = 'http://localhost:4000'; // LOCAL
const URL = 'https://dev-api.rocket-tools.io'; // DEV
// const URL = 'https://api.rocket-tools.io'; // PRD

export const API = axios.create({
  baseURL: URL,
  timeout: 3000,
  headers: {
    'Content-Type': 'application/json',
  },
});

import { useEffect, useState } from 'react';

import './App.css';
import styled from 'styled-components';
import GlobalStyle from './styles/Global';
import { selectPopup } from './utils/logic';
import { setSuspension } from './utils/date';
import { Popup } from './domains/feature-popup/Popup';
import { setInvisibleBackground, setVisibleBackground } from './utils';

const Wrapper = styled.div`
  position: relative;
  width: -webkit-fill-available;
  width: 100vw;
  height: 100vh;
  width: 100dvw;
  height: 100dvh;
  padding: 0 10px;
`;
const PopupPositional = styled.div`
  position: absolute;
  max-width: 400px;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
  @media (max-width: 400px) {
    width: calc(100% - 48px);

    /* left: initial;
    top: initial; */
    /* bottom: 0px; */
    /* transform: initial; */
  }
`;

const ModalPortal = styled.div`
  width: 100%;
  height: 100%;
  z-index: 99999;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-start;
`;

const App = () => {
  const [modalOpened, setModalOpened] = useState(false);
  const [RTService, setRTService] = useState<any>(null);
  const [title, setTitle] = useState('');
  const [detail, setDetail] = useState('');
  const [buttonMessage, setButtonMessage] = useState('');
  const [buttonCancelMessage, setButtonCancelMessage] = useState('');
  const [buttonUrl, setButtonUrl] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [suspensionHour, setSuspensionHour] = useState(0);
  const [design, setDesign] = useState('');
  const [retryCount, setRetryCount] = useState(0);
  const [campaignId, setCampaignId] = useState(0);
  const [campaignBlockId, setCampaignBlockId] = useState(0);

  const handleAccept = () => {
    setSuspension(campaignId, campaignBlockId, suspensionHour);
    const { host } = window.parent.location;
    const redirectUrl = `https://${host}${buttonUrl}`;
    window.parent.location.href = redirectUrl;
  };

  const handleKakao = () => {
    setSuspension(campaignId, campaignBlockId, suspensionHour);
    const currentUrl = window.parent.location.href;
    localStorage.setItem('_rt_kcru', currentUrl);
    window.parent.postMessage('kbtfrtck', '*');
    setInvisibleBackground();
    setModalOpened(false);
  };

  const handleConditionalAccept = () => {
    if (design === 'kakao') {
      handleKakao();
    } else {
      handleAccept();
    }
  };

  const handleReject = () => {
    setSuspension(campaignId, campaignBlockId, suspensionHour);
    setInvisibleBackground();
    setModalOpened(false);
  };

  useEffect(() => {
    const rtService = window.parent.RTService;
    setRTService(rtService);

    if (!rtService || !rtService.mallInfo || !rtService.userInfo) {
      console.log(`ytinit`);
      const retryTimeout = setTimeout(() => {
        setRetryCount(retryCount + 1);
      }, 500);
      return () => clearTimeout(retryTimeout);
    }
    console.log('compinit');

    const fetchPopup = async () => {
      try {
        const { mallId, shopNo } = rtService.mallInfo;

        const selectedPopup = await selectPopup(mallId, shopNo);

        if (selectedPopup == null) {
          setInvisibleBackground();
        } else {
          if (selectedPopup.mainMessage && selectedPopup.mainMessage !== '') {
            setTitle(selectedPopup.mainMessage);
            setVisibleBackground();
            setModalOpened(true);
          }
          if (selectedPopup.subMessage && selectedPopup.subMessage !== '') {
            setDetail(selectedPopup.subMessage);
          }
          if (
            selectedPopup.buttonMessage &&
            selectedPopup.buttonMessage !== ''
          ) {
            setButtonMessage(selectedPopup.buttonMessage);
          }
          if (
            selectedPopup.buttonCancelMessage &&
            selectedPopup.buttonCancelMessage !== ''
          ) {
            setButtonCancelMessage(selectedPopup.buttonCancelMessage);
          }
          if (selectedPopup.buttonUrl && selectedPopup.buttonUrl !== '') {
            setButtonUrl(selectedPopup.buttonUrl);
          }
          if (selectedPopup.imageUrl && selectedPopup.imageUrl !== '') {
            setImageUrl(selectedPopup.imageUrl);
          }
          setCampaignId(selectedPopup.campaignId);
          setCampaignBlockId(selectedPopup.campaignBlockId);
          if (
            selectedPopup.suspensionHour &&
            selectedPopup.suspensionHour !== null
          ) {
            setSuspensionHour(Number(selectedPopup.suspensionHour));
          }
          if (selectedPopup.design && selectedPopup.design !== '') {
            setDesign(selectedPopup.design);
          }
        }
      } catch (err) {
        console.log(err);
        setInvisibleBackground();
      }
    };

    const timeoutId = setTimeout(fetchPopup, 1000);
    return () => clearTimeout(timeoutId);
  }, [retryCount]);

  return (
    <Wrapper className="App">
      <GlobalStyle />
      {/* {modalOpened ||
        (true && (
          <Modal
            title={title}
            detail={detail}
            imageUrl={imageUrl}
            buttonMessage={buttonMessage}
            handleAccept={handleAccept}
            buttonCancelMessage={buttonCancelMessage}
            handleReject={handleReject}
          />
        ))} */}
      {modalOpened && (
        <ModalPortal>
          <PopupPositional>
            <Popup
              design={design}
              mainText={title}
              subText={detail}
              imageUrl={imageUrl}
              actionButton={{ message: buttonMessage }}
              cancelButton={{ message: buttonCancelMessage }}
              handleAccept={handleConditionalAccept}
              handleReject={handleReject}
            />
          </PopupPositional>
        </ModalPortal>
      )}
    </Wrapper>
  );
};

export default App;

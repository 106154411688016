import styled from 'styled-components';
import { semicolonToNewLine } from '../../utils/format';
import { colors } from '../../styles/color';
import { KakaoIcon } from '../../assets/KakaoIcon';

const Wrapper = styled.div`
  height: 100%;
  width: 100%;

  background: ${colors.$White};
  overflow: hidden;

  border-radius: 8px;
  /* @media (max-width: 400px) {
    border-radius: 20px 20px 0 0;
  } */
`;

const PopupEmpty = styled.div`
  height: calc(100lvh - 100svh);
`;

const PopupContentSection = styled.div`
  font-family: Spoqa Han Sans Neo;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 24px 28px 12px;

  @media (max-width: 400px) {
    padding: 24px 24px 12px;
    gap: 14px;
  }
`;

const TextSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: flex-start;
`;

const MainText = styled.p`
  margin: 0px;
  font-weight: 700;
  font-size: 24px;
  color: ${colors.$Gray90};
  white-space: pre-wrap;
  overflow-wrap: break-word;
  text-align: start;
  line-height: 30.05px;
  letter-spacing: -0.02em;
  font-family: Spoqa Han Sans Neo;

  @media (max-width: 400px) {
    font-size: 20px;
    line-height: 25.04px;
  }
`;

const SubText = styled.p`
  margin: 0px;
  font-weight: 400;
  font-size: 14px;
  color: ${colors.$Gray90};
  white-space: pre-wrap;
  overflow-wrap: break-word;
  text-align: start;
  line-height: 130%;
  font-family: Spoqa Han Sans Neo;

  line-height: 17.53px;
  letter-spacing: -0.02em;

  @media (max-width: 400px) {
    font-size: 14px;
    line-height: 19.6px;
  }
`;
const ButtonSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  align-items: center;
`;

const PopupMainButton = styled.button<{
  design: string;
}>`
  font-family: Spoqa Han Sans Neo;
  display: flex;
  padding: 14px 8px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 10px;
  background: #1a1d1f;
  border: none;

  color: #ffffff;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  white-space: pre-wrap;
  overflow-wrap: break-word;

  line-height: 25.04px;
  letter-spacing: -0.02em;

  ${(props) => props.design === 'kakao' && `background: #FDE20A;`}
  ${(props) => props.design === 'kakao' && `color: #131201;`}

  @media (max-width: 400px) {
    font-size: 14px;
    line-height: 17.53px;
  }

  cursor: pointer;
`;

const PopupSubButton = styled.button`
  background: none;
  border: none;

  color: #a2a9b0;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.02em;
  text-decoration-line: underline;

  white-space: pre-wrap;
  overflow-wrap: break-word;

  @media (max-width: 400px) {
    font-size: 14px;
    line-height: 17.53px;
  }

  cursor: pointer;
`;

interface PopupProps {
  mainText: string;
  subText: string;
  actionButton: {
    message: string;
  };
  cancelButton: {
    message: string;
  };
  imageUrl: string;
  handleAccept: () => void;
  handleReject: () => void;
  design: string;
}
export const Popup = ({
  mainText,
  subText,
  actionButton,
  cancelButton,
  imageUrl,
  handleAccept,
  handleReject,
  design,
}: PopupProps) => {
  return (
    <Wrapper>
      {imageUrl && (
        <img
          src={imageUrl}
          alt="popup"
          width={338}
          height={245}
          style={{
            width: '100%',
            height: '100%',
          }}
        />
      )}
      <PopupContentSection>
        <TextSection>
          <MainText>{semicolonToNewLine(mainText)}</MainText>
          <SubText>{semicolonToNewLine(subText)}</SubText>
        </TextSection>
        <ButtonSection>
          <PopupMainButton onClick={handleAccept} design={design}>
            {design === 'kakao' && <KakaoIcon />}
            {semicolonToNewLine(actionButton.message)}
          </PopupMainButton>
          <PopupSubButton onClick={handleReject}>
            {semicolonToNewLine(cancelButton.message)}
          </PopupSubButton>
        </ButtonSection>
      </PopupContentSection>
      <PopupEmpty />
    </Wrapper>
  );
};

import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  *, *::before, *::after {
    box-sizing: border-box;
  }

  body {
    margin: 0;
    padding: 0;
    
    font-family: Spoqa Han Sans Neo, sans-serif;
    background-color: transparent;
  }

  span ,div, img {
    margin: 0;
    padding: 0;
  }
  
`;

export default GlobalStyle;

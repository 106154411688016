export const sessionKey = '_rt_anltcs_sess';
export const userKey = '_rt_anltcs_user';
export const popupKey = '_rt_anltcs_popup';

export const setVisibleBackground = () => {
  const background = window.parent.document.getElementById(
    'rt-plugin-background',
  );
  const container = window.parent.document.getElementById(
    'rt-plugin-container',
  );
  if (background) {
    background.style.width = '100vw';
    background.style.width = '100lvw';
    background.style.height = '100vh';
    background.style.height = '100lvh';
  }
  if (container) {
    container.style.width = '100vw';
    container.style.width = '100lvw';
    container.style.height = '100vh';
    container.style.height = '100lvh';
  }
};

export const setInvisibleBackground = () => {
  const background = window.parent.document.getElementById(
    'rt-plugin-background',
  );
  const container = window.parent.document.getElementById(
    'rt-plugin-container',
  );
  if (background) {
    background.style.width = '0vw';
    background.style.height = '0vh';
  }
  if (container) {
    container.style.width = '0vw';
    container.style.height = '0vh';
  }
};

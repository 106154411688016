export const colors = {
  $Gray10: '#f6f7fa',
  $Gray20: '#dde1e6',
  $Gray30: '#bfc5cb',
  $Gray40: '#a2a9b0',
  $Gray50: '#878d96',
  $Gray60: '#697077',
  $Gray80: '#343a3f',
  $Gray90: '#1a1d1f',
  $BLUE05: '#f5fbfe',
  $Blue10: '#e0f2fe',
  $Blue50: '#4f8eff',
  $Blue100: '#0f62fe',
  $Black: '#000',
  $White: '#fff',
  $Red5: '#fff0f0',
  $Red100: '#e32636',
  $LightGreen: '#e8f5e9',
  $Green: '#44b359',
  $Red: '#e32636',
  $Red05: '#fff0f0',
  $Red10: '#fff1f0',
  $Red20: '#ffccc7',
  $Red50: '#ff7875',
  // $Red100: '#ff4d4f',
  // $Green: '#44b359',
  $Green10: '#f0fff4',
  $Green20: '#c6f6d5',
  $Green50: '#57d9a3',
  $Green100: '#00b67a',
  $LightYellow: '#fff9e6',
  $Yellow: '#ffd700',
};

import { API } from '.';
import { Popup, PossibleLiquidKeys } from '../types/popup';

export interface Session {
  deviceId: string;
  sessionId: string;
  userId: string;
  signUpAt: Date;
  visitCount: number;
  validViewCount: number;
  totalViewCount: number;
  currentPage: string;
  scrollMax: string;
  expiredAt: Date;
  platform: string;
  userAgent: string;
}

export interface Suspension {
  campaignId: number;
  campaignBlockId: number;
  date: Date;
}

export interface ReqGetPopup {
  session: Session | null;
  suspension: Suspension[];
  mallId: string;
  shopNo: number;
  liquids?: {
    [key in PossibleLiquidKeys]?: string;
  };
}

export interface ResGetPopup {
  status: number;
  result: Popup;
}

export const getPopup = async (req: ReqGetPopup) => {
  try {
    const response = await API.post<ResGetPopup>(
      `/popup/${req.mallId}/${req.shopNo}?${
        req.liquids &&
        Object.keys(req.liquids || {})
          // @ts-ignore
          .map((key: PossibleLiquidKeys) =>
            req.liquids ? `&${key}=${req.liquids[key] ?? ''}` : '',
          )
          .join('')
      }`,
      { session: req.session, suspension: req.suspension },
    );

    if (response.status !== 200) {
      throw new Error('Get Popup Recommendation Failed');
    }

    const { result } = response.data;

    return result;
  } catch (err: any) {
    throw new Error(err.message);
  }
};

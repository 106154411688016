import { popupKey, sessionKey, setInvisibleBackground } from '.';
import { Session, Suspension, getPopup } from '../services/popupService';
import { Popup } from '../types/popup';

type PopupKeyType =
  | 'visitCount'
  | 'validViewCount'
  | 'totalViewCount'
  | 'signUpAt';

const getLocalStorageSession = async (
  retries: number = 50,
  interval: number = 100,
) => {
  let attempt = 0;

  const sleep = (ms: number) =>
    new Promise((resolve) => {
      setTimeout(resolve, ms);
    });

  while (attempt < retries) {
    if (attempt % 10 === 0 || attempt % 10 === 5) {
      console.log(`lst ${attempt}`);
    }
    if (attempt === 0) {
      // eslint-disable-next-line no-await-in-loop
      await sleep(4000);
    }
    const storedSession = localStorage.getItem(sessionKey);
    if (storedSession !== null) {
      return JSON.parse(storedSession) as Session;
    }
    // eslint-disable-next-line no-await-in-loop
    await sleep(interval);
    attempt += 1;
  }

  setInvisibleBackground();
  return null;
};

/**
 * 로직에 사용되는 popup의 항목: targetPage, type, condition, value, delay, suspensionHour
 *
 * targetUser: 팝업이 노출될 사용자 (all, guest, member)
 * targetPage: 팝업이 노출될 페이지
 *
 * type: 팝업이 노출되는 조건 (validViewCount 등 localStorage에 저장된 값)
 * condition: 팝업이 노출되는 조건의 비교 연산자
 * value: 팝업이 노출되는 조건의 값
 * {type} {condition} {value} 형태 | ex. validViewCount >= 2
 *
 * delay: 팝업이 노출되기까지의 시간 (초 단위)
 * suspensionHour: 팝업을 닫았을 때 노출되지 않는 시간 (시간 단위)
 */
export const selectPopup = async (mallId: string, shopNo: number) => {
  const session: Session | null = await getLocalStorageSession();
  const suspensionValue = localStorage.getItem(popupKey);
  let suspension: Suspension[] = [];
  if (suspensionValue != null) {
    suspension = JSON.parse(suspensionValue);
  }

  const { userInfo } = window.parent.RTService;

  const handleDelay = (popup: Popup) => {
    return new Promise<Popup | null>((resolve) => {
      setTimeout(() => {
        resolve(popup);
      }, popup.delay * 1000); // delay는 초 단위
    });
  };

  const popup: Popup = await getPopup({
    session,
    suspension,
    mallId,
    shopNo,
    liquids: {
      userId: userInfo.memberId,
      userName: userInfo.nickName,
      userPhone: userInfo.phoneNumber,
    },
  });

  if (popup == null) {
    setInvisibleBackground();
    return null;
  }

  return handleDelay(popup);

  // const targetPopup = popupList.filter(
  //   (popup) =>
  //     popup.targetPage.split(',').includes(session!.currentPage) &&
  //     userEvaluation(session!, popup),
  // );

  // const nonePopup = targetPopup.find((popup) => popup.type === 'none');
  // const conditionPopup = targetPopup.find((popup) => popup.type !== 'none');

  // if (conditionPopup) {
  //   if (popupConditionEvaluation(session!, conditionPopup)) {
  //     if (isWithinSuspension(conditionPopup, suspension)) {
  //       console.log('susPP');
  //       setInvisibleBackground();
  //       return null;
  //     }
  //     console.log('conditionPP');
  //     return handleDelay(conditionPopup);
  //   }
  // }
  // if (nonePopup) {
  //   if (isWithinSuspension(nonePopup, suspension)) {
  //     console.log('susPP');
  //     setInvisibleBackground();
  //     return null;
  //   }
  //   console.log('nonePP');
  //   return handleDelay(nonePopup);
  // }

  // console.log('nullPP');
  // return null;
};

// const isWithinSuspension = (popup: Popup, suspensions: Suspension[]) => {
//   const suspension = suspensions.find(
//     (sus) =>
//       sus.campaignId === popup.campaignId &&
//       sus.campaignBlockId === popup.campaignBlockId,
//   );

//   if (suspension) {
//     if (validateSuspension(new Date(suspension.date))) {
//       return true;
//     }
//     return false;
//   }
//   return false;
// };

// const userEvaluation = (session: Session, popup: Popup) => {
//   if (popup.targetUser === 'all') {
//     return true;
//   }
//   if (popup.targetUser === 'guest') {
//     if (session.userId === '' || session.userId == null) {
//       return true;
//     }
//     return false;
//   }
//   if (popup.targetUser === 'member') {
//     if (session.userId !== '' && session.userId != null) {
//       return true;
//     }
//     return false;
//   }
//   return false;
// };

// const conditionEvaluation = (
//   sessionValue: number,
//   condition: string,
//   value: number,
// ) => {
//   switch (condition) {
//     case '>':
//       return sessionValue > value;
//     case '<':
//       return sessionValue < value;
//     case '>=':
//       return sessionValue >= value;
//     case '<=':
//       return sessionValue <= value;
//     case '==':
//       return sessionValue === value;
//     case '!=':
//       return sessionValue !== value;
//     default:
//       return false;
//   }
// };

// const popupConditionEvaluation = (session: Session, popup: Popup) => {
//   const types = popup.type.split(',');
//   const conditions = popup.condition.split(',');
//   const values = popup.value.split(',').map(Number);

//   for (let i = 0; i < types.length; i += 1) {
//     let sessionValue = session[types[i] as PopupKeyType];
//     const condition = conditions[i];
//     const value = values[i];

//     if (types[i] === 'signUpAt') {
//       sessionValue = getTimeDifference(new Date(session.signUpAt));
//     }

//     if (!conditionEvaluation(Number(sessionValue), condition, value)) {
//       console.log('cef');
//       return false;
//     }
//   }

//   return true;
// };

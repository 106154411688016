import { popupKey } from '.';
import { Suspension } from '../services/popupService';

export const validateSuspension = (suspendedUntil: Date) => {
  const now = new Date();
  const suspendedDate = new Date(suspendedUntil);
  const valid = now.getTime() < suspendedDate.getTime();
  console.log(`susval${valid}`);
  return valid;
};

export const setSuspension = (
  campaignId: number,
  campaignBlockId: number,
  hour?: number,
) => {
  const suspensionHour = hour == null ? 12 : hour;
  const now = new Date();
  const hourConverted = suspensionHour * 60 * 60 * 1000;
  const suspensionDate = new Date(now.getTime() + hourConverted);

  let suspensionList: Suspension[] = [];
  const currentValue = localStorage.getItem(popupKey);
  if (currentValue != null) {
    suspensionList = JSON.parse(currentValue);
  }

  let result: Suspension[] = [];
  if (suspensionList.length > 0) {
    result = suspensionList.filter(
      (sus) =>
        sus.campaignId !== campaignId &&
        sus.campaignBlockId !== campaignBlockId,
    );
  }

  const newSuspension: Suspension = {
    campaignId,
    campaignBlockId,
    date: suspensionDate,
  };
  result.push(newSuspension);

  localStorage.setItem(popupKey, JSON.stringify(result));
};

// 단위: minute
// 하루: 1440
// 1주일: 10080
export const getTimeDifference = (
  timestampA: Date,
  timestampB: Date = new Date(),
): number => {
  const timeA: number = Math.floor(timestampA.getTime() / 1000);
  const timeB: number = Math.floor(timestampB.getTime() / 1000);
  const diffMinutes: number = Math.abs(timeA - timeB) / 60;

  return diffMinutes;
};
